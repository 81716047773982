const state = {
	commonObj: {
		hasDropdownLoaded: true,
		countryList: [],
		locationList: [],
		cityList: [],
		otherCurrencyList: []
	},
	token: localStorage.getItem('access_token') || null,
	user: JSON.parse(localStorage.getItem('access_user'))?.user || { name: null, picture: null },
	sidebarMinimize: false,
	lang: 'en',
	currency: {},
	static: {
		loading: false,
		listReload: false,
        perPage: 10
	},
	list: []
}

export default state