import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
// import store from '../store/store'


// all route for main page
const pagesRoutes = [
    {
        path: '',
        name: 'Home',
        component: () => import ('@/pages/HomePage.vue'),
    },
    {
        path: '/all-rooms',
        name: 'Home',
        component: () => import ('@/pages/AllRooms.vue'),
    },
    {
        path: '/otp/:id',
        component: () => import ('@/pages/OtpVerify.vue')
    },
    {
        path: '/forget-password',
        component: () => import ('@/pages/ForgetPassword.vue')
    },
    {
        path: '/change-password/:id',
        component: () => import ('@/pages/ChangePassword.vue')
    },
    {
        path: '/contact-us',
        component: () => import ('@/pages/ContactUs.vue')
    },
    {
        path: '/room-details/:id',
        component: () => import ('@/pages/RoomView.vue')
    }
    // {
    //     path: '/ads',
    //     component: () => import ('@/pages/ProductList.vue')
    // },
    // {
    //     path: '/ads/:location',
    //     component: () => import ('@/pages/ProductList.vue')
    // },
    // {
    //     path: '/ads/:location/:category',
    //     component: () => import ('@/pages/ProductList.vue')
    // },
    // {
    //     path: '/product/:slug',
    //     component: () => import ('@/pages/ProductDetails.vue')
    // },
    // {
    //     path: '/shops/:slug',
    //     component: () => import ('@/pages/MemberProducts.vue')
    // },
    // {
    //     path: '/post-ad',
    //     component: () => import ('@/pages/PublishAdd.vue'),
    //     meta: {
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: '/faq',
    //     component: () => import ('@/pages/FaqPage.vue')
    // },
    // {
    //     path: '/help',
    //     component: () => import ('@/pages/HelpPage.vue')
    // },
    // {
    //     path: '/about',
    //     component: () => import ('@/pages/AboutPage.vue')
    // },
    // {
    //     path: '/chat',
    //     component: () => import ('@/pages/ChatPage.vue')
    // },
    // {
    //     path: '/safety-tips',
    //     component: () => import ('@/pages/SafetyPage.vue')
    // },
];

// all route for profile
const profileRoutes = [
    {
        path: 'booking-request',
        component: () => import ('@/pages/profile/BookingList.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'booking-approved',
        component: () => import ('@/pages/profile/ApproveBookingList.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'booking-cancel',
        component: () => import ('@/pages/profile/BookingList.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'update',
        component: () => import ('@/pages/profile/Profile.vue'),
        meta: {
            requiresAuth: true
        }
    }
];

const routes = [
    {
        path: '/',
        name: 'MainLayout',
        component: () => import('@/layouts/MainLayout.vue'),
        children: pagesRoutes
    },
    {
        path: '/profile',
        name: 'ProfileLayout',
        component: () => import('@/layouts/ProfileLayout.vue'),
        children: profileRoutes
    },
    
]




const router = new VueRouter({
    mode: 'history',
    base: '/',
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    routes
  })

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (store.getters.isLoggedIn) {
//             next()
//             return
//         }
//         next('/')
//     } else {
//         next()
//     }
// })


export default router  