import Vue from 'vue'
import App from './App.vue'
import './plugins'
import './assets/css/main.css'
import router from './router'
import store from './store/store'
import 'izitoast/dist/css/iziToast.min.css'; // loading css

Vue.config.productionTip = false

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel)

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules'

// Register it globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
